// -----------------------------------------------------------------------------
// Instagram js
// -----------------------------------------------------------------------------

const instagramPhotoGallery = document.querySelector('.js-instagram-photos');
const token = '1824830267.1677ed0.61212654eb2b4348bf2bc93a64f3e839';
const userid = 'self';
// const userid = 1824830267;

if (instagramPhotoGallery) {
  const instagramPhotos = fetch(`https://api.instagram.com/v1/users/${userid}/media/recent/?access_token=${token}`)
    .then(response => response.json())
    .then(response => {
      // console.log(response);
      response.data.map(item => {
        const instagramPhoto = document.createElement('figure');
        instagramPhoto.className = 'instagram-photos__item';

        instagramPhoto.innerHTML = `
        <a href="${item.link}" target="_blank" class="instagram-photos__link">
          <img src="${item.images.standard_resolution.url}" alt="${
          item.caption ? item.caption : ''
          }" class="instagram-photos__image"/>
        </a>
      `;

        instagramPhotoGallery.appendChild(instagramPhoto);
      });
    });
}

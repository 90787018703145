// -----------------------------------------------------------------------------
// Homepage js
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Comments Slider
// -----------------------------------------------------------------------------

const commentSlider = tns({
  container: '.js-slides',
  nav: false,
  autoplayButtonOutput: false,
  slideBy: 'page',
  autoplay: true,
  autoplayHoverPause: true,
  autoHeight: false,
  autoplayTimeout: 15000,
  fixedWidth: 300,
  animateDelay: true,
  speed: 800,
  mouseDrag: true,
  controlsContainer: '.js-slides-controls',
  responsive: {
    768: {
      fixedWidth: 400,
    },
  },
});

// -----------------------------------------------------------------------------
// Show/hide comment form
// -----------------------------------------------------------------------------

const openComments = document.querySelector('.js-open-comments');
const commentsForm = document.querySelector('.comment-respond');

if (openComments) {
  openComments.addEventListener('click', () => {
    commentsForm.classList.toggle('show-comments');
  });
}

// -----------------------------------------------------------------------------
// Google API
// -----------------------------------------------------------------------------

const fakeElement = document.createElement('div');
const reviewsList = document.querySelector('.js-reviews-list');
const request = { placeId: 'ChIJH_5BvGqFdUgRfgI83Ldh7EE' };
const service = new google.maps.places.PlacesService(fakeElement);
const reviewItemHeightArr = [];

/* Get do-lalli json from google
========================================================================== */
service.getDetails(request, (place, status) => {
  if (status === google.maps.places.PlacesServiceStatus.OK) {
    /* If successful, display review if rating over 4
    ========================================================================== */
    place.reviews.forEach(review => {
      if (review.rating >= 4 && reviewsList) {
        const reviewsListItem = document.createElement('li');
        reviewsListItem.className = 'review';
        reviewsListItem.innerHTML = `
          <a class="review__meta" href="${review.author_url}" target="_blank">
            <img class="review__image" src="${review.profile_photo_url}" alt="${review.author_name}" />
            <h3 class="review__author">${review.author_name}</h3>
            <p class="review__date">${review.relative_time_description}</p>
          </a>
          <div class="review__text">
              <div class="review__rating review__rating--${review.rating}"></div>
              ${review.text}
          </div>
          `;
        reviewsList.appendChild(reviewsListItem);
        reviewItemHeightArr.push(reviewsListItem.offsetHeight);
      }
      // console.log(reviewItemHeightArr);
    });

    /* Set heights to max height
    ========================================================================== */

    const reviewText = Array.from(document.querySelectorAll('.review__text'), reviewTextItem => {
      reviewTextItem.style.height = `${Math.max(...reviewItemHeightArr) + 5}px`;
      // console.log(Math.max(...reviewItemHeightArr) + 5);
      // console.log(reviewTextItem.offsetHeight);
    });

    /* Create slider from reviews
    ========================================================================== */
    const reviewSlider = tns({
      container: '.js-reviews-list',
      items: 1,
      autoplayButtonOutput: false,
      slideBy: 'page',
      mode: 'gallery',
      autoplayHoverPause: true,
      autoplay: true,
      animateDelay: true,
      controls: false,
      autoplayTimeout: 15000,
      speed: 800,
      nav: '.js-review-slides-controls',
    });

    /* Set opening hours
    ========================================================================== */

    const jsOpeningHours = document.querySelector('.js-opening-hours');
    const openingHours = place.opening_hours;
    // const jsOpenStatus = document.querySelector('.js-open-status');
    const jsWeekday = document.querySelector('.js-weekday');
    const jsSaturday = document.querySelector('.js-saturday');
    const jsSunday = document.querySelector('.js-sunday');

    /* Currently open message
    ========================================================================== */
    // if (openingHours.open_now) {
    //   jsOpenStatus.innerText = 'We are currently open!';
    // }
    /* Opening hours https://stackoverflow.com/a/4607799 splits on first :
    ========================================================================== */

    if (jsOpeningHours) {
      jsWeekday.innerText = openingHours.weekday_text[0].split(/:(.+)/)[1];
      jsSaturday.innerText = openingHours.weekday_text[5].split(/:(.+)/)[1];
      jsSunday.innerText = openingHours.weekday_text[6].split(/:(.+)/)[1];

      /* Business details
      ========================================================================== */

      const jsTel = document.querySelector('.js-tel-link');
      const jsAddress = document.querySelector('.js-address');
      const jsBusinessName = document.querySelector('.js-name');
      const jsMapUrl = document.querySelector('.js-map-url');

      jsTel.href = `tel:${place.formatted_phone_number}`;
      jsTel.innerText = place.formatted_phone_number;
      jsAddress.innerHTML = place.adr_address;
      jsBusinessName.innerText = place.name;
      jsMapUrl.href = place.url;
    }
  }
});
